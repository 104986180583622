p {
  color: #333333
}

ul p {
  color: #444444;
  font-size: 0.9em;
}

h1, h2, h3, h4 {
  color: #A72264
}

span {
  color: #333333
}
